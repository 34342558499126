import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const DatenschutzPage = () => (
  <Layout>
    <SEO title='Datenschutzerklärung' />
    <h1>Datenschutz</h1>
    <div style={{ overflowY: "scroll", height: "500px" }}>
      <p>
        Uns ist das Thema Datenschutz sehr wichtig. Deshalb halten wir sämtliche
        Datenschutzgesetze ein und informieren Sie hiermit umfassend, welche
        Daten wir speichern und wie Ihre Daten verwendet werden. Bitte beachten
        Sie, dass unsere Seiten auch Hyperlinks auf andere Webseiten beinhalten.
        Wir haben keinen Einfluss auf deren Inhalte und deren
        Datenschutz-bestimmungen.
      </p>
      <h2>Kontakt mit uns</h2>
      <p>
        Wenn Sie per Formular auf der Website/App oder per E-Mail Kontakt mit
        uns aufnehmen, werden Ihre angegebenen Daten zwecks Bearbeitung der
        Anfrage und für den Fall von Anschlussfragen sechs Monate bei uns
        gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
      </p>
      <p>
        Wenn Sie per Social-Media wie zB: WhatsApp oder Facebook-Messenger mit
        uns in Kontakt treten, werden ihre Kontaktdaten sowie IP-Adresse an den
        Dienstleister übermittelt und dort gespeichert, zudem werden Ihre
        angegebenen Daten zwecks Bearbeitung der Anfrage und für den Fall von
        Anschlussfragen sechs Monate bei uns gespeichert.
      </p>
      <h2>Personenbezogene Daten</h2>
      <p>
        Wenn du ein Konto auf dieser Website/App besitzt oder Kommentare
        geschrieben hast, kannst du einen Export deiner personenbezogenen Daten
        bei uns anfordern, inklusive aller Daten, die du uns mitgeteilt hast.
        Darüber hinaus kannst du die Löschung aller personenbezogenen Daten, die
        wir von dir gespeichert haben, anfordern. Dies umfasst nicht die Daten,
        die wir aufgrund administrativer, rechtlicher oder sicherheitsrelevanter
        Notwendigkeiten aufbewahren müssen.
      </p>
      <h2>Nutzung personenbezogener Daten</h2>
      <p>
        Für zahlreiche Funktionen auf unserer Seite ist es nötig, ein Konto zu
        erstellen. Bei der Registrierung sind persönliche Daten wie Name,
        Telefonnummer, Email und Wohnort wahrheitsgemäß anzugeben. Sobald die
        Registrierung durchgeführt wurde, können registrierte Benutzer
        Beratungen senden und viele weitere Funktionen nutzen.
      </p>
      <p>
        Es ist unbedingt nötig eine funktionierende persönliche Telefonnummer
        anzugeben, da ansonsten keine Konto-Freischaltung oder
        Bestellbestätigung erfolgen kann.{" "}
      </p>
      <p>
        Wir weisen darauf hin, dass zum Zweck des einfacheren Einkaufsvorganges
        und zur späteren Vertragsabwicklung vom Webshop-Betreiber im Rahmen von
        Cookies die IP-Daten des Anschlussinhabers gespeichert werden, ebenso
        wie Name und Anschrift des Käufers sowie die ausgewählten Waren und das
        Kaufdatum.
      </p>

      <h2>Kommentare</h2>
      <p>
        Wenn Besucher Kommentare auf der Website schreiben, sammeln wir die
        Daten, die im Kommentar-Formular angezeigt werden, außerdem die
        IP-Adresse des Besuchers und den User-Agent-String (damit wird der
        Browser identifiziert), um die Erkennung von Spam zu unterstützen.
      </p>
      <h2>Eingebettete Inhalte von anderen Websites</h2>
      <p>
        Beiträge auf dieser Website können eingebettete Inhalte beinhalten
        (z. B. Videos, Bilder, Beiträge etc.). Eingebettete Inhalte von anderen
        Websites verhalten sich exakt so, als ob der Besucher die andere Website
        besucht hätte.
      </p>
      <p>
        Diese Websites können Daten über dich sammeln, Cookies benutzen,
        zusätzliche Tracking-Dienste von Dritten einbetten und deine Interaktion
        mit diesem eingebetteten Inhalt aufzeichnen, inklusive deiner
        Interaktion mit dem eingebetteten Inhalt, falls du ein Konto hast und
        auf dieser Website angemeldet bist.
      </p>
      <h2>Weitergabe personenbezogener Daten</h2>
      <p>
        Eine Datenübermittlung an Dritte erfolgt nur bei ausdrücklicher
        Zustimmung über die Website oder App.{" "}
      </p>
      <p>
        Im Falle eines Vertragsabschlusses werden sämtliche Daten aus dem
        Vertragsverhältnis bis zum Ablauf der steuerrechtlichen
        Aufbewahrungsfrist (7 Jahre) gespeichert. Die Daten „Name“, „Anschrift“,
        „gekaufte Waren“ und „Kaufdatum“ werden darüber hinaus gehend bis zum
        Ablauf der Produkthaftung (10 Jahre) gespeichert.  Die Datenverarbeitung
        erfolgt auf Basis der gesetzlichen Bestimmung des § 96 Abs 3 TKG sowie
        des Art 6 Abs 1 lit a (Einwilligung) und/oder b (notwendig zur
        Vertragserfüllung) der DSGVO.
      </p>

      <h2>Schutz der Daten</h2>
      <p>
        Wir nutzen höchste und modernste Sicherheitsstandards zum Schutz aller
        Daten auf den Cloud- Servern.
      </p>
      <h2>Löschung von Daten</h2>
      <p>
        Wenn Sie Ihr Konto deaktivieren, behalten wir uns vor, Ihre Daten
        weiterhin am Server gespeichert zu lassen und Ihnen eine Reaktivierung
        Ihres Accounts zu erleichtern. Die Daten „Name“, „Anschrift“, „gekaufte
        Waren“ und „Kaufdatum“ werden darüber hinaus gehend bis zum Ablauf der
        Produkthaftung (10 Jahre) gespeichert.
      </p>
      <h2>Verarbeitung von Nutzungsinformationen</h2>
      <p>
        Um Missbrauch bei Bewertungen vorzubeugen und für statistische Zwecke
        werden mit Ihrem Zugriff auf unserer Seite anonymisierte Daten
        gespeichert. Dazu zählen unter anderem Ihre IP-Adresse, Uhrzeit und
        Browserinformationen. Die Auswertung dieser Daten dient der Verbesserung
        unserer Dienste und erfolgt vollständig automatisiert und anonym.
      </p>
      <p>
        Wenn du einen Kommentar schreibst, wird dieser inklusive Metadaten
        zeitlich unbegrenzt gespeichert. Auf diese Art können wir
        Folgekommentare automatisch erkennen und freigeben, anstelle sie in
        einer Moderations-Warteschlange festzuhalten.
      </p>
      <p>
        Für Benutzer, die sich auf unserer Website registrieren, speichern wir
        zusätzlich die persönlichen Informationen, die sie in ihren
        Benutzerprofilen angeben. Alle Benutzer können jederzeit ihre
        persönlichen Informationen einsehen, verändern oder löschen (der
        Benutzername kann nicht verändert werden). Administratoren der Website
        können diese Informationen ebenfalls einsehen und verändern.
      </p>
      <h2>Cookies</h2>
      <p>
        Unsere Website verwendet so genannte Cookies. Dabei handelt es sich um
        kleine Textdateien, die mit Hilfe des Browsers auf Ihrem Endgerät
        abgelegt werden. Sie richten keinen Schaden an. Wir nutzen Cookies dazu,
        unser Angebot nutzerfreundlich zu gestalten. Einige Cookies bleiben auf
        Ihrem Endgerät gespeichert, bis Sie diese löschen. Sie ermöglichen es
        uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.
      </p>
      <p>
        Wenn Sie dies nicht wünschen, so können Sie Ihren Browser so einrichten,
        dass er Sie über das Setzen von Cookies informiert und Sie dies nur im
        Einzelfall erlauben. Bei der Deaktivierung von Cookies kann die
        Funktionalität unserer Website eingeschränkt sein.
      </p>
      <h2>Web-Analyse</h2>
      <p>
        Wir benutzen Google Analytics, einen Webanalysedienst der Google Inc.,
        1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (“Google”).
        Google Analytics verwendet Cookies, Textdateien, die auf Ihrem Computer
        gespeichert werden und die eine Analyse der Benutzung der Website durch
        Sie ermöglichen. Die durch das Cookie erzeugten Informationen über Ihre
        Benutzung dieser Website werden in der Regel an einen Server von Google
        in den USA übertragen und dort gespeichert. Im Falle der Aktivierung der
        IP-Anonymisierung auf dieser Webseite, wird Ihre IP-Adresse von Google
        jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in
        anderen Vertragsstaaten des Abkommens über den Europäischen
        Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle
        IP-Adresse an einen Server von Google in den USA übertragen und dort
        gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese
        Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um
        Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit
        der Websitenutzung und der Internetnutzung verbundene Dienstleistungen
        gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google
        Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit
        anderen Daten von Google zusammengeführt. Sie können die Speicherung der
        Cookies durch eine entsprechende Einstellung Ihrer Browser-Software
        verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall
        gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich
        werden nutzen können. Sie können darüber hinaus die Erfassung der durch
        das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten
        (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten
        durch Google verhindern, indem sie das unter dem folgenden Link
        (http://tools.google.com/dlpage/gaoptout?hl=de) verfügbare
        Browser-Add-on herunterladen und installieren. Unter dem genannten Link
        können Sie sich auch zum Browser-Add-on informieren.
      </p>
      <p>
        Weitere Informationen zum Datenschutz von Google Analytics finden Sie
        unter http://www.google.com/intl/de/analytics/privacyoverview.html
      </p>
      <h2>Social Media</h2>
      <p>
        Wir haben auf unseren Seiten Plugins von Facebook, 1601 South California
        Avenue, Palo Alto,CA 94304, USA integriert. Der Besuch auf unserer Seite
        bewirkt eine direkte Verbindung zu Facebook – Servern. Auf diese Art und
        Weise wird Facebook mitgeteilt, dass Ihre IP – Adresse auf unserer
        Webseite registriert wurde. Sofern Sie nun den „Like“, „Gefällt mir“,
        „Teilen“ oder einen entsprechenden Button betätigen, ordnet Facebook
        Ihre IP-Adresse automatisch Ihrem Benutzerkonto zu und es wird Ihnen
        ermöglicht, unsere Webseite bei Facebook, auf den Seiten Ihres Profils,
        zu verlinken. Dieser Vorgang kann nur stattfinden, wenn Sie parallel bei
        Facebook eingeloggt sind. Über die Nutzung und die Art der erhobenen
        Daten haben wir leider keine Kenntnis. Für weitere Informationen
        diesbezüglich verweisen wir auf Facebook. Sollten Sie keine Zuordnung
        Ihres Benutzerkontos zu Ihrer IP-Adresse wünschen, dann loggen Sie sich
        bitte im Vorfeld der Nutzung unserer Webseite aus Ihrem Facebook Konto
        aus.
      </p>
      <h2>Google Fonts</h2>
      <p>
        Unsere Seiten laden Schriftarten-Dateien vom Dienst Google Fonts ein, um
        Ihnen eine schönere Webseite anbieten zu können. Google Fonts ist ein
        Dienst der Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA
        94043, USA (“Google”). Beim Aufruf unserer Seiten wird Ihr Webbrowser
        angewiesen, die dazu notwendigen Funktionen und Kartendaten direkt vom
        Server von Google zu laden. Diese Server können in den USA oder anderen
        Ländern der Welt stehen. Wir haben hierzu keine Steuerungsmöglichkeiten
        und erhalten keine Informationen von Google, ob Sie beim Besuch unserer
        Seite entsprechende Karteninformationen ausgetauscht haben. Uns ist
        nicht bekannt, ob Google Ihnen nur die technisch notwendigen
        Informationen ausliefert oder weitere Daten über Sie oder Ihr System,
        wie zB. IP-Adressen, Informationen über Ihren Browser o.ä. speichert und
        auswertet. Google veröffentlich eine Datenschutzerklärung
        (Link: https://www.google.com/intl/de/policies/privacy/index.html), die
        auch für Google Fonts gilt.
      </p>
      <h2>Google Maps</h2>
      <p>
        Diese Webseite verwendet das Produkt Google Maps von Google Inc., 1600
        Amphitheatre Parkway, Mountain View, CA 94043, USA (“Google”). Durch
        Nutzung dieser Webseite erklären Sie sich mit der Erfassung, Bearbeitung
        sowie Nutzung der automatisiert erhobenen Daten durch Google Inc, deren
        Vertreter sowie Dritter einverstanden.  Die Nutzungsbedingungen von
        Google Maps finden sie unter „Nutzungsbedingungen von Google Maps“.
      </p>

      <h2>Google Dienste</h2>
      <p>
        Google erfasst gerätespezifische Informationen, beispielsweise das
        Modell der von Ihnen verwendeten Hardware, die Version des
        Betriebssystems, eindeutige Gerätekennungen und Informationen über das
        Mobilfunknetz einschließlich Ihrer Telefonnummer. Google verknüpft Ihre
        Gerätekennungen oder Telefonnummer gegebenenfalls mit Ihrem
        Google-Konto.
      </p>
      <p>
        Wenn Sie Google-Dienste nutzen, erfassen und verarbeiten wir
        möglicherweise Informationen über Ihren tatsächlichen Standort. Wir
        verwenden zur Standortbestimmung verschiedene Technologien, wie
        IP-Adressen, GPS und andere Sensoren, die Google beispielsweise
        Informationen über nahe gelegene Geräte, WLAN-Zugangspunkte oder
        Mobilfunkmasten bereitstellen.
      </p>
      <h2>Widerspruchsrecht</h2>
      <p>
        Sollten Sie der weiteren Nutzung Ihrer personenbezogenen Daten für die
        Zukunft widersprechen, so können Sie das per Löschung Ihres Accounts
        tun. Um darüber hinaus sämtliche Daten unwiederbringlich zu löschen,
        senden Sie bitte einen schriftlichen Widerspruch inklusive gültigem
        Nachweis, dass es sich um Ihre Informationen handelt, per E-Mail.
      </p>
    </div>
  </Layout>
)

export default DatenschutzPage
